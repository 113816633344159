import React, { Component } from 'react';
import Main from './components/Main'
import './App.scss';
import { Provider } from  'react-redux';
import { configureStore } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter } from 'react-router-dom';

class App extends Component {

  constructor(props){
    super(props);

  }

  render(){
    const { store, persistor } = configureStore();

    /** temporary debug for persistor */
    if( window.location.search === '?debug' ){
      persistor.purge();
    }
    return (
      <Provider store={store} >    
        <BrowserRouter>
          <div className="App">
            <Main />
          </div>
        </BrowserRouter>   
      </Provider>
    );
  }
}

export default App;
