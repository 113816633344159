import React, { useState } from "react";
import PropTypes from "prop-types";

import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import Linkify from "react-linkify";

const iconStyle = {
  fontSize: "10pt",
  color: "#aaa",
  cursor: "pointer",
};

const CustomModal = (props) => {
  const { body, title, modalClass, buttonClass } = props;

  const [open, setOpen] = useState(false);

  const toggle = () => setOpen(!open);

  return (
    <div>
      <FontAwesomeIcon
        onClick={toggle}
        icon={faInfoCircle}
        className="hi"
        style={iconStyle}
      />
      <Modal isOpen={open} className={modalClass} toggle={toggle}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a
                target="blank"
                style={{ color: "red" }}
                href={decoratedHref}
                key={key}
              >
                {decoratedText}
              </a>
            )}
          >
            {body.split("<br>").map((d, i) => (
              <p key={`text-${i}`}>{d}</p>
            ))}
          </Linkify>
        </ModalBody>
      </Modal>
    </div>
  );
};

CustomModal.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  buttonClass: PropTypes.string,
  modalClass: PropTypes.string,
};

export default CustomModal;
