import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { actions } from "react-redux-form";
import PropTypes from "prop-types";
import {
  Jumbotron,
  Navbar,
  NavbarBrand,
  Nav,
  NavbarToggler,
  NavItem,
  NavLink,
  Collapse,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Label,
  Input,
  Alert,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faPenSquare,
  faChartPie,
} from "@fortawesome/free-solid-svg-icons";

import OAALogo from "../public/images/oaa.svg";

const api_url = process.env.REACT_APP_API_URL;

import { loginUser, logoutUser } from "../redux/ActionCreators";

const LoggedInDisplay = (props) => {
  const user = props.auth.user;

  return (
    <Nav>
      <NavItem
        className={"d-flex align-middle align-items-center"}
        style={{ color: "#333" }}
      >
        <p className={"mb-0 align-middle"}>
          {" "}
          Welcome, {user ? user.userName : null}
        </p>
      </NavItem>
      <NavItem className={"d-flex align-items-center justify-center"}>
        <Button
          style={{
            color: "#333",
            backgroundColor: "transparent",
            border: "none",
          }}
          className="d-flex align-items-center py-0"
          onClick={() => {
            props.logoutUser();
            props.resetForm();
            props.toggleOff(false);
          }}
        >
          <p className={"mb-0 align-middle"}> Logout </p>
        </Button>
      </NavItem>
    </Nav>
  );
};

LoggedInDisplay.propTypes = {
  auth: PropTypes.any,
  logoutUser: PropTypes.func,
  resetForm: PropTypes.func,
  toggleOff: PropTypes.func,
};

const LoginModal = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const submissionHandler = async (e) => {
    const creds = { username: username, password: password };
    props.loginUser(creds);
  };

  return (
    <div className={"d-flex align-items-center"}>
      <Button
        style={{
          color: "#333",
          backgroundColor: "transparent",
          border: "none",
        }}
        className="d-flex align-items-center"
        onClick={() => {
          props.toggle();
        }}
      >
        Login
      </Button>
      <Modal isOpen={props.modalOpen} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle}>Login</ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              submissionHandler(e);
            }}
          >
            <FormGroup>
              {props.err && props.err != "" && (
                <Alert color="danger">{props.err}</Alert>
              )}
              <Label for="username">Username</Label>{" "}
              <Input
                onChange={(e) => setUsername(e.target.value)}
                type="text"
                name="username"
                id="username"
              />
            </FormGroup>
            <FormGroup>
              <Label for="password">Password</Label>{" "}
              <Input
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                name="password"
                id="password"
              />
            </FormGroup>{" "}
            <div className={"form-footer"}>
              <Button type="submit" className={"action-button"}>
                Login
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

LoginModal.propTypes = {
  toggle: PropTypes.func,
  loginUser: PropTypes.func,
  modalOpen: PropTypes.bool,
  err: PropTypes.string,
};

const Header = (props) => {
  const [navOpen, setNavOpen] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);

  const [err, setErr] = useState(null);

  const { auth } = props;

  useEffect(() => {
    setErr(null);
    if (auth.errMessage) {
      setErr(auth.errMessage);
      setModalOpen(true);
    } else if (auth.isAuthenticated) {
      toggle();
    }
  }, [auth]);

  const toggle = () => {
    setErr(null);
    setModalOpen(!modalOpen);
  };

  return (
    <div className="container header-container pb-4 border-bottom-black d-print-none">
      <Navbar expand="md" className="mx-auto ">
        <div className="px-0 nav-contain">
          <div className="row row-header px-0 mt-4">
            <NavbarBrand href="/" className="d-inlines">
              <div>
                <a href="https://oaa.on.ca/" target="_blank" rel="noreferrer">
                  <img
                    src={OAALogo}
                    alt="Ontario Association of Architects "
                    id="oaa-logo-header"
                    width="355px"
                  />
                </a>
              </div>
            </NavbarBrand>
            {props.auth.isAuthenticated ? (
              <LoggedInDisplay
                auth={props.auth}
                logoutUser={props.logoutUser}
                resetForm={props.resetForm}
                toggleOff={setModalOpen}
              />
            ) : (
              <LoginModal
                toggle={toggle}
                modalOpen={modalOpen}
                loginUser={props.loginUser}
                err={err}
              />
            )}
          </div>
        </div>
      </Navbar>
    </div>
  );
};

Header.propTypes = {
  auth: PropTypes.any,
  loginUser: PropTypes.func,
  logoutUser: PropTypes.func,
  resetForm: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loginUser: (creds) => dispatch(loginUser(creds)),
  logoutUser: () => dispatch(logoutUser()),
  resetForm: () => dispatch(actions.reset("model")),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
