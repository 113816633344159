import React from 'react';
import PropTypes from 'prop-types';

import { Col, Row } from 'reactstrap';

const style ={ 
  background: {
  	backgroundImage: `url("assets/images/emptyHouse.svg")`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: "100% 100%",
    border: "0px",
    boxShadow: "none"
  },
  box: {
    paddingTop: "50px",
    width: "125px" 
  },
  bottomText: {
  	textIndent: "0rem"
  }

}

const House = (props) => {
  const { metric } = props;

  return (
    <div className="house-container" style={style.background}>
      <div style={style.box} className="pl-3 mx-auto">
        <p>
          This Building 
        </p>
        <p>
          <span>{metric}</span>
        </p>
        <p style={style.bottomText}>
          {"\u0009"}kWh/m{"\u00b2"}/yr
        </p>
      </div>
    </div>
  )
}

House.propTypes = {
  metric: PropTypes.number
}

export default House;