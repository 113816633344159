import { combineReducers } from "redux";
import { combineForms, formReducer } from "react-redux-form";
import { persistReducer } from "redux-persist";
import localStorage from "redux-persist/lib/storage";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";

export const initialState = {
  email: "",
  userInputId: "",
  companyName: "",
  projectNumber: "",
  conditionedArea: 0.0,
  monthlyElectricUse: 0.0,
  naturalGasUse: 0.0,
  propaneUse: 0.0,
  heatingOil: 0.0,
  biofuelUse: 0.0,
  onSiteContributions: 0.0,
  beginDate: new Date(),
  endDate: new Date(),
  usingElectricity: "",
  usingNaturalGas: "",
};
