import React, { useState, useEffect }from 'react';
import PropTypes from "prop-types";
import {Col, Row, Label, Input, InputGroup, InputGroupText, InputGroupAddon } from 'reactstrap';
import { Control, Errors, actions } from 'react-redux-form';

import InfoCon from '../InfoCon';

import { converter, rounder } from './../../utilities/conversionMethods.js';

const ConditionedInput = (props) => {

  const {
    model, 
    conversionConstant, 
    dispatch, 
    field, 
    text, 
    toolText, 
    validators, 
    validatorMessages } = props;
 
  // neccessary mutation for managing bi-directional field components - CRDenaux
  const initialArea = (value) => value ? converter(value, conversionConstant) : null;
  const [ areaFeet, setAreaFeet ] = useState( initialArea(model.conditionedArea) );

  useEffect(() => {
    if( Math.abs( initialArea( model[field] ) - areaFeet ) > 0.09 ){
      setAreaFeet( rounder( initialArea(model.conditionedArea) ) );
    }
  }, [model])

    return (
      <React.Fragment>
        <Row>
          <Col md={12}>
            <Label htmlFor={`model.${field}`}>{text}
              {
                toolText && <InfoCon id={`Tooltip-${field}`} text={toolText} />
              }
            </Label> 
          </Col>
        </Row>
        <Row>
          <Col md={6} xs={6}>
            <InputGroup>
              <Control
            model={`model.${field}`} 
            id={`model.${field}`} 
            className="form-control small-input"
            placeholder="0"
            changeAction={(model, value) => {
              dispatch(actions.change(model, converter(value, conversionConstant, false) ))
              }
            }
            updateOn="blur"
            validators={validators}
            onChange={(e) => setAreaFeet(e.target.value)}
            value={areaFeet}
                
          />
              <InputGroupAddon addonType="append">
                <InputGroupText>ft &#178;</InputGroupText>
              </InputGroupAddon>         
            </InputGroup>
            <Errors
            className="text-danger"
            model={`.${field}`}
            show={{touched: true, focus: false}}
            messages={{
                  required: 'Required. ',
                  isNumber: 'Numerals Only'
              }}
          /> 
          </Col>
          <Col md={6} xs={6}>
            <InputGroup>
              <Control.text 
            model={`model.${field}`} 
            id={`model.${field}`} 
            className="form-control small-input"
            placeholder="0"
            updateOn="blur"
            validators={validators}
            validateOn="blur"
          />
              <InputGroupAddon addonType="append">
                <InputGroupText>m &#178;</InputGroupText>
              </InputGroupAddon>         
            </InputGroup>
            <Errors
            className="text-danger"
            model={`.conditionedArea`}
            show={{touched: true, focus: false}}
            messages={{
                  required: 'Required. ',
                  isNumber: 'Numerals Only'
              }}
          /> 
          </Col>
        </Row>
      </React.Fragment>
    );

   
}

ConditionedInput.propTypes = {
  field: PropTypes.string,
  text: PropTypes.string,
  toolText: PropTypes.string,
  placeholder: PropTypes.string,
  validators: PropTypes.object,
  validatorMessages: PropTypes.object,
  model: PropTypes.object,
  dispatch: PropTypes.func,
  conversionConstant: PropTypes.number
};

export default ConditionedInput;