import React, { useState, useEffect } from "react";
import { Row, Col, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import PropTypes from "prop-types";
import { printPDF, emailPDF } from "../utilities/printFunction";

//Email Popup message
const EmailPopup = (props) => {
  const { body, open, toggle } = props;

  const errorHandler = () => {
    if (body == "Error") {
      return (
        <p>
          There was an error sending this email, please
          <a href="https://oaa.on.ca/about/contact-us"> contact us </a>
          to resolve the issue.
        </p>
      );
    } else {
      return <p style={{ whiteSpace: "pre-wrap" }}>{body}</p>;
    }
  };

  return (
    <div className="pt-4 d-print-none">
      <Modal isOpen={open} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <strong>Email TEUI Estimate</strong>
        </ModalHeader>
        <ModalBody>{errorHandler()}</ModalBody>
      </Modal>
    </div>
  );
};

EmailPopup.propTypes = {
  body: PropTypes.string,
  open: PropTypes.bool,
  toggle: PropTypes.func,
};

//Main component
const ButtonContainer = (props) => {
  const { title, disabled } = props;

  const [emailModal, setEmailModal] = useState(false);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);

  useEffect(() => {
    async function asyncExport() {
      await exportPDF();
    }
    if (isPrinting) {
      asyncExport();
      setIsPrinting(false);
    }
  }, [isPrinting]);

  const exportPDF = async () => {
    await printPDF("estimate-container-print", title, "button-container");
    props.postEstimate(model, props.auth);
  };

  //ensure necessary data is extracted/formatted from model
  const cleanData = (values) => {
    var newValues = {};

    var items = [
      "conditionedArea",
      "monthlyElectricUse",
      "propaneUse",
      "heatingOil",
      "biofuelUse",
      "onSiteContributions",
    ];

    for (let key in values) {
      if (items.indexOf(key) == -1) {
        newValues[key] = values[key];
      } else {
        newValues[key] = Number(values[key]);
      }
    }

    return newValues;
  };

  const model = cleanData(props.model);

  const toggle = () => {
    setEmailModal(!emailModal);
    if (emailModal) {
      setResponse(null);
    }
  };

  const emailHandler = async () => {
    props.setToggled(true);
    setLoading(true);
    setResponse("Sending Email, please wait...");
    let res = await emailPDF(
      "estimate-container-print",
      title,
      model,
      "button-container",
      props.setToggled
    );
    setLoading(false);
    var message = "";
    if (res.success) {
      message = `${res.message} \nSent to: ${model.email} \nFile name: ${title}`;
    } else {
      message = `Error`;
    }
    setResponse(message);
    props.postEstimate(model, props.auth);
  };

  useEffect(() => {
    if (response && loading) {
      toggle();
    }
  }, [response]);

  return (
    <Row
      id={"button-container"}
      className={"button-container pt-4 mt-4 px-2 d-print-none"}
    >
      <Col md={6}>
        <Button
          color="secondary"
          size="sm"
          disabled={disabled}
          onClick={() => setIsPrinting(true)}
        >
          {" "}
          Print Form
        </Button>
      </Col>
      <Col md={6}>
        <Button
          color="secondary"
          size="sm"
          onClick={emailHandler}
          disabled={disabled}
        >
          {" "}
          Email Form
        </Button>
      </Col>
      <EmailPopup open={emailModal} toggle={toggle} body={response} />
    </Row>
  );
};

ButtonContainer.propTypes = {
  title: PropTypes.string,
  postEstimate: PropTypes.func,
  model: PropTypes.object,
  auth: PropTypes.object,
  setToggled: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ButtonContainer;
