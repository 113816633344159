import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';

import { Col, Row } from 'reactstrap';

import { normalizeValue } from './../../utilities/conversionMethods.js';

const colorScheme = {
  "Oil": "#000000", 
  "Nat. Gas": "#ffd13b", 
  "Propane": "#FFA500",
  "Electricity": "#5f9dff", 
  "BioFuel": "#996632", 
  "Renewables": "#0000ff",
  "Other": "#AAA"
}

const createDonutChart = (data, el) => {

  console.log(data, "donuts!!!!")
  
  var placeholderDonut = data.filter((d) => Number(d.value) !== 0);
  if (placeholderDonut.length === 0) {
    data.filter((d) => d.name === "BioFuel")[0].value = 0.00001;
  }

	const container = d3.select("#"+el);
	container.selectAll("#"+el+" > *").remove();

	let svg = container.append("svg")
	  .attr("preserveAspectRatio", "xMinYMin meet")
    .attr("viewBox", "0 0 360 250");

  let width = svg.node().getBoundingClientRect().width;

	let g = svg.append("g")
    .attr("width", "100%")
    .attr("transform", "translate( 0," + (225/2) + ")");

  let donutSection = g.append("g").attr("width", (width * .60) )
    .attr("transform", "translate(" + 85 + ", 10)");
  let legendSection = g.append("g").attr("width", ( width * .40) )
    .attr("transform", "translate(" + ( width * .60 )+ ",20)");

  const radius = 80;

  var color = d3.scaleOrdinal()
    .domain( Object.keys(data) )
    .range( colorScheme );

  var maxVal = d3.max(data, (d) => Number(d.value) );

  var data_ready = d3.pie()
    .sort(null) 
    .value(function(d) {
      let nValue = normalizeValue(d.value, maxVal);
      nValue = nValue < 10 && nValue != 0 ? 10 : nValue;
      return nValue; 
    })(data);

  // The arc generator
  var arc = d3.arc()
    .innerRadius(radius * 0.6)
    .outerRadius(radius * 0.75)

  var outerArc = d3.arc()
    .innerRadius(radius * 0.85)
    .outerRadius(radius * 0.85)

  donutSection.selectAll('allSlices')  
    .data(data_ready)
    .enter()
    .append('path')
    .transition()
    .duration(750)
    .attr('d', (d) => {
      return arc( d ) 
    } )  
    .attr('fill', function(d){ 
      return ( (placeholderDonut.length === 0) ? "#aaa" : colorScheme[d.data.name] ) 
    })
    .attr("transform", "translate(0, -5)")

  donutSection.selectAll('labels')
    .data(data_ready)
    .enter()
    .append('text')
      .attr("width", "50px")
      .attr("height", "60px")
      .text( function(d) { 
        if(placeholderDonut.length === 0 && d.data.name === "BioFuel") {
          return ;
        } else {
          let percentage = (Number(d.data.value)/100) * 100;
          if(percentage > 0){
            let val = parseFloat(percentage) < 1 ? parseFloat( d.data.value ).toPrecision(2) : parseInt(d.data.value)  ;
            return val;
          }
        }
      })
      .attr('transform', function(d) {
          var pos = outerArc.centroid(d);
          return 'translate(' + pos + ')';
      })
      .style('text-anchor', function(d) {
          var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2
          return (midangle < Math.PI ? 'start' : 'end')
      })
      .style("font-size", "9pt")

  let legend = legendSection.selectAll("squares")
    .data(data_ready.map((d) => d.data.name) )
    .enter();

  legend.append("text")
    .attr("x", 25)
    .attr("y", - 100 )
    .text((d => "Units: kWh/m\u00B2/yr" ))

  legend.append("rect")
    .attr("width", 15)
    .attr("height", 15)
    .attr("x", 25)
    .attr("y", (d, i) => - 85 + (i * 35) )
    .attr("rx", 3)
    .attr("ry", 3)
    .attr("stroke", "transparent")
    .attr("fill", (d) => colorScheme[d] )

  legend.append("text")
    .attr("x", 43)
    .attr("y", (d, i) => - 73 + (i * 35) )
    .attr("stroke", "black")
    .attr("stroke-width", 0.15)
    .attr("font-size", "9pt")
    .text((d)=> d )

}

const DonutChart = (props) => {

  const { data, element} = props;
  const container ="donut-chart" + element;
     
  useEffect(()=>{
  	createDonutChart(data, container); 
  },[data])

  return (
    <div id="donut-container" title="Breakdown of TEUI by energy source">
      <div id={container}></div>
    </div>
  )

}

DonutChart.propTypes = {
  data: PropTypes.array,
  element: PropTypes.string
}

export default DonutChart;