import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const iconStyle = {
  fontSize: "10pt",
  color: "#aaa",
};

const InfoCon = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <React.Fragment>
      <FontAwesomeIcon
        icon={faInfoCircle}
        id={`${props.id}`}
        style={iconStyle}
      />
      <Tooltip
        className="tooltip-area"
        placement="right"
        isOpen={tooltipOpen}
        target={`${props.id}`}
        toggle={() => setTooltipOpen(!tooltipOpen)}
      >
        {props.text}
      </Tooltip>
    </React.Fragment>
  );
};

InfoCon.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
};

export default InfoCon;
