import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Header from "./Header";
import Footer from "./Footer";
import Landing from "../views/Landing";
import PrimaryForm from "../views/PrimaryForm";
import Estimate from "../views/Estimate";
import CustomContainer from "./CustomContainer";
import { actions } from "react-redux-form";

class Main extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    window.onbeforeunload = () => {
      this.props.resetForm();
    };

    return (
      <div className="main-container">
        <Header />
        <Switch>
          <Route path="/" component={Landing} />
          <Redirect to="/" />
        </Switch>
        <Footer />
      </div>
    );
  }
}

Main.propTypes = {
  resetForm: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  resetForm: () => dispatch(actions.reset("model")),
});

export default connect(null, mapDispatchToProps)(Main);
