import jsPdf from "jspdf";
import domtoimage from "dom-to-image-more";
import html2canvas from "html2canvas";
import axios from "axios";
import { changeDpiDataUrl } from "changedpi";

const api_url = process.env.REACT_APP_API_URL + "estimates/email-estimate";

const scale = 2;

const style = (width, height, translate) => {
  return {
    flexFlow: "column",
    visibility: "visible",
    justifyContent: "flex-start",
    alignItems: "center",
    alignContent: "center",
    alignSelf: "center",
    transformOrigin: "top left",
    width: width * 0.35 + "px",
    maxHeight: 450 * 0.35 + "px",
    transform: "scale(" + scale + ") translate(" + 70 + "px, 60px)",
  };
};

const postFile = (file, userId, email, projectNumber, filename) => {
  const data = new FormData();
  data.append("pdf", file);
  data.append("email", email);
  data.append("projectNumber", projectNumber);
  data.append("filename", filename);
  data.append("link", process.env.REACT_APP_API_URL);

  return axios({
    method: "post",
    data: data,
    url: api_url,
    headers: {
      "Content-Type": `multipart/form-data;`,
      userkey: userId,
    },
  })
    .then((res) => {
      if (res.data.success) {
        return {
          err: null,
          message: "Email sent successfully!",
          success: true,
        };
      } else {
        return {
          err: res.data.err,
          message: "There was an error sending this email.",
          success: false,
        };
      }
    })
    .catch((err) => {
      console.log(err, "err!");
      return {
        err: err,
        message: "There was an error sending this email.",
        success: false,
      };
    });
};

export const printPDF = async (
  container,
  title,
  button = null,
  togglePrint
) => {
  function filter(node) {
    return node.id !== button;
  }

  const domElement = document.getElementById(container);
  domElement.style.visibility = "visible";
  domElement.style.position = "relative";
  const pdf = new jsPdf("p", "in", "letter");
  pdf.internal.scaleFactor = 30;
  const width = domElement.offsetWidth;
  const height = domElement.offsetHeight;
  const pageWidth = pdf.internal.pageSize.getWidth();

  const pageRatioX = ((width * 0.0104166667) / pageWidth) * 96;

  domtoimage
    .toJpeg(domElement, {
      filter: filter,
      width: 440 * scale * 1.3,
      height: 900 * scale * 1.3,
      bgcolor: "#fff",
      quality: 1,
      style: style(width, 1080, pageRatioX),
    })
    .then((imgData) => {
      let marginX = pageWidth / 2 - (440 * 0.0104166667) / 2;
      pdf.setLineWidth(0.05);
      pdf.rect(marginX, 1, 440 * 0.0104166667, 900 * 0.0104166667);
      pdf.addImage(
        imgData,
        "JPEG",
        marginX,
        1,
        440 * 0.0104166667,
        900 * 0.0104166667,
        null,
        "NONE"
      );
      domElement.style.visibility = "hidden";
      domElement.style.position = "absolute";
      pdf.save(title);
    });
};

export const emailPDF = async (
  container,
  title,
  model,
  button = null,
  togglePrint,
  userId
) => {
  function filter(node) {
    return node.id !== button;
  }

  const domElement = document.getElementById(container);
  domElement.style.visibility = "visible";
  domElement.style.position = "relative";
  const pdf = new jsPdf("p", "in", "letter", true);
  pdf.internal.scaleFactor = 30;
  const width = domElement.offsetWidth;
  const height = domElement.offsetHeight;
  const pageWidth = pdf.internal.pageSize.getWidth();

  const pageRatioX = ((width * 0.0104166667) / pageWidth) * 96;

  let response = await domtoimage
    .toPng(domElement, {
      filter: filter,
      width: 440 * scale * 1.3,
      height: 900 * scale * 1.3,
      style: style(width, 1080, pageRatioX),
    })
    .then((imgData) => {
      let marginX = pageWidth / 2 - (440 * 0.0104166667) / 2;
      pdf.setLineWidth(0.05);
      pdf.rect(marginX, 1, 440 * 0.0104166667, 900 * 0.0104166667);
      pdf.addImage(
        imgData,
        "JPEG",
        marginX,
        1,
        440 * 0.0104166667,
        900 * 0.0104166667,
        null,
        "FAST"
      );
      domElement.style.visibility = "hidden";
      domElement.style.position = "absolute";

      var binary = pdf.output("blob");

      return postFile(binary, userId, model.email, model.projectNumber, title);
    })
    .catch((err) => {
      console.log(err, "error");
      return {
        err: err,
        message: "Something went wrong",
      };
    });

  togglePrint(false);
  return response;
};
