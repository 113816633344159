import React, { useState, useEffect, useRef } from 'react';
import PropTypes from "prop-types";
import { Label, Collapse, Button, Input, InputGroup, InputGroupAddon, InputGroupText, Col, Row } from 'reactstrap';
import { Control, Errors, actions } from 'react-redux-form';

import MonthInputs from './MonthInputs';
import ToggleButton from '../ToggleButton';
import InfoCon from '../InfoCon';

import { converter, rounder } from './../../utilities/conversionMethods.js';


const CollapsibleInput = (props) => {

  const { model, 
          dispatch, 
          placeholder, 
          field, 
          text, 
          toolText,
          validators, 
          validatorMessages, 
          fieldValues 
        } = props;

  const { twoPart, fieldOne, fieldTwo, constant } = fieldValues;

  const modelVal = model[field];

  const initialArea = (val) => val ? converter( val, constant, false ) : null;

  const [ open, setOpen ] = useState(false);
  const [ value, setValue ] = useState( initialArea(modelVal) );

  const updateModel = () => {
    if( Math.abs(initialArea( modelVal ) - value ) > 0.009 ){
      setValue( initialArea( modelVal ) );
    }     
  } 
  
  function useDidUpdateEffect(fn, inputs) {
    const didMountRef = useRef(false);

    useEffect(() => {
      if (didMountRef.current)
        fn();
      else
        didMountRef.current = true;
    }, inputs);
  }


  useDidUpdateEffect(updateModel, [model])
   
  return (
    <Row className="pt-2 collapsible-input">
      <Col md={12}>
        <Label htmlFor={`model.${field}`}>
          {text}
          {toolText && <InfoCon id={`Tooltip-${field}`} text={toolText} />}
        </Label>
      </Col>
      <ToggleButton open={open} handler={setOpen} />
      <Col className="pb-1" md={12}>
        <Collapse isOpen={open}>
          <Control
            model={`model.${field}`}
            component={MonthInputs}
            reducer={setValue}
            field={field}
            converter={converter}
            constant={constant}
          />
        </Collapse>
      </Col>
      <Col className="pb-1" md={6} xs={6}>
        <div className="">
          <InputGroup>
            <Control.text
              model={`model.${field}`}
              id={`model.${field}-total`}
              className="form-control small-input"
              placeholder="0"
              validators={validators}
              changeAction={(model, val) => {
                dispatch(actions.change(model, converter(val, constant)));
              }}
              updateOn="change"
              onChange={(e) => {
                setValue(e.target.value);
              }}
              value={value}
              readOnly={open ? true : false}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText className="form-control">
                {fieldOne.units}
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
          <Errors
            className="text-danger"
            model={`.${field}`}
            show={{ touched: true }}
            messages={{
              isNumber: "Numerals Only",
            }}
          />
        </div>
      </Col>
      {twoPart && (
        <Col className="pb-1" md={6} xs={6}>
          <div className="">
            <InputGroup>
              <Control.text
                model={`model.${field}`}
                id={`model.${field}`}
                className="form-control small-input"
                placeholder="0"
                updateOn="change"
                validateOn="blur"
                readOnly={open ? true : false}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText className="form-control">
                  {fieldTwo.units}
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </div>
        </Col>
      )}
    </Row>
  );
}

CollapsibleInput.propTypes = {
  field: PropTypes.string,
  text: PropTypes.string,
  toolText: PropTypes.string,
  placeholder: PropTypes.string,
  validators: PropTypes.object,
  validatorMessages: PropTypes.object,
  model: PropTypes.object,
  dispatch: PropTypes.func,
  fieldValues: PropTypes.object
};

export default CollapsibleInput;