import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { actions } from 'react-redux-form';
import { Row, Col, Input } from 'reactstrap';

const MonthInputs = (props) => {

  //if this element is open, make aggregate fields read only
  const months = [{key: "Jan", sum: 0}, 
                  {key: "Feb", sum: 0}, 
                  {key: "Mar", sum: 0}, 
                  {key: "Apr", sum: 0}, 
                  {key: "May", sum: 0},
                  {key: "Jun", sum: 0}, 
                  {key: "Jul", sum: 0}, 
                  {key: "Aug", sum: 0}, 
                  {key: "Sep", sum: 0}, 
                  {key: "Oct", sum: 0}, 
                  {key: "Nov", sum: 0}, 
                  {key: "Dec", sum: 0}];

    
  const { reducer, dispatch, model, field, converter, constant } = props;

  const [ sum, setSum ] = useState( model[field] );

  const [ monthContainer, setMonthContainer ] = useState(months)
  

  const handleInputChange = (e, i) => {
    let value = e.target.value;
    if(!isNaN(value)){
      value = Number(value);
      monthContainer[i].sum = value;
      let newSum = monthContainer.reduce((acc, {sum}) => acc + sum, 0 );
      setMonthContainer([...monthContainer]);
      setSum(newSum);   
      reducer(newSum);
    }
  }

  useEffect(() => {
    dispatch( actions.change(`model.${field}`, converter(sum, constant)) );
  }, [sum])

  return (
    <Row className="pl-3" key={`model.${field}-month-container`} id={`model.${field}-month-container`} >
      {
    	months.map((month, i) => {
    		return (
      <Col key={`model.${field}-month-${i}`} md={1} xs={2} className="pr-1 pl-0">
        <Input 
              onChange={(e) => handleInputChange(e, i) } 
              placeholder={ month.key } 
              className="small-input"
            />
      </Col>
    		)
    	})
    }     	
    </Row>
  )

}

MonthInputs.propTypes = {
	reducer: PropTypes.func,
  dispatch: PropTypes.func,
  model: PropTypes.object,
  field: PropTypes.string,
  converter: PropTypes.func,
  constant: PropTypes.any
}

const mapStateToProps = state => {
  return {
    model: state.model
  }
}

export default withRouter(connect(mapStateToProps)(MonthInputs));
