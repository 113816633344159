export const fieldValues = {
  subhead: {
    description: `The first step is to know, and the second step is to act.
      This TEUI Calculator helps you determine the Total Energy Use Intensity of
      a building or home. Simply enter your information in the fields below.
      Only for Ontario-based projects.`,
    modalText: `Canada committed to meet the climate targets set in the Paris Agreement,
      but most individuals (even architects) are in the dark about the performance of their own homes.
      The Total Energy Use Intensity (TEUI) rating is just starting to gain traction in the
      architecture/engineering/construction (AEC) world, and the OAA has aimed to make this
      data visually obvious with simple input from a year’s worth of your energy bills. <br> This handy
      calculator lets you determine the energy performance of your home or building in the units
      of the most widely used building energy metric, TEUI. The units are the amount of all
      energy sources in Kilowatt Hours (kWh) required as a function of building area in square
      metres per year: kWh/m\u00B2/yr. Simply fill in the fields to the best of your knowledge and the
      calculator converts all of the units for you. Conversion references are provided in the footnotes
      below. Happy crunching! <br> This project was initiated in the summer of 2019 by the Ontario
      Association of Architects (OAA) Sustainable Built Environments Committee (SBEC).
      For more information about the OAA, visit www.oaa.on.ca .`
  },
  nonAuthTooltipText: "Log in with your OAA member account using the Login link in the upper right of the page",
  email: {
    label: "10. Your Email: ",
    placeholder: "you@example.com",
    toolText: "Enter your email."

  },
  userInputId:{
    label: "11. OAA License Number: ",
    placeholder: "007 Bond",
    toolText: "We'll need license verification in order to print."

  },
  projectNumber:{
    label: "12. Project Number: ",
    placeholder: "09237...",
    toolText: "Provide the project number for reference."
  },
  companyName:{
    label: "13. Company Name: ",
    placeholder: "Your Company",
    toolText: "List your company's name."
  },
  conditionedArea:{
    label: "1. What is the total conditioned area of your building? ",
    constant: 10.764,
    toolText: "Help account for the space you are heating and cooling."
  },
  monthlyElectricUse: {
    label: "2. Enter any Electricity used here (kWh): ",
    twoPart: false,
    fieldOne: {
      units: "kWh Total/yr"
    },
    fieldTwo: null,
    constant: 1,
    toolText: "You can enter monthly values or by year."
  },
  naturalGasUse: {
    label: '3. Enter any Natural Gas used here (m\u00B3): ',
    twoPart: true,
    fieldOne: {
      units:  `m\u00B3 Total/yr`
    },
    fieldTwo: {
      units: `m\u00B3 to kWh`,
    },
    constant: 10.2764,
    toolText: "You can enter monthly values or by year."
  },
  propaneUse: {
    label: "4. Enter any Propane used here (lbs): ",
    twoPart: true,
    fieldOne: {
      units:  `lbs Total/yr`
    },
    fieldTwo: {
      units: `lbs to kWh`,
    },
    constant: 6.3267,
    toolText: "You can enter monthly values or by year."
  },
  heatingOil: {
    label: '5. Enter any Heating Oil used here (litres): ',
    twoPart: true,
    fieldOne: {
      units:  `L Total/yr`
    },
    fieldTwo: {
      units: `L to kWh`,
    },
    constant: 10.36,
    toolText: "You can enter monthly values or by year."
  },
  biofuelUse: {
    label: '6. Enter any Biofuel/Wood used here (m\u00B3): ',
    twoPart: true,
    fieldOne: {
      units:  `m\u00B3 Total/yr`
    },
    fieldTwo: {
      units: `m\u00B3 to kWh`,
    },
    constant: 1000,
    toolText: "You can enter monthly values or by year."
  },
  onSiteContributions: {
    label: "7. Enter On-Site Renewable Contributions here (Solar/Wind): ",
    twoPart: false,
    fieldOne: {
      units: "kWh/yr"
    },
    fieldTwo: null,
    constant: 1,
    toolText: "You can enter monthly values or by year."
  }, bullfrog: {
    label: '9. Are you using off-site renewable energy?',
    toolText: "Estimated consumption from Bullfrog provider."
  },
}
