import React, { useState } from "react";
import PropTypes from "prop-types";
import { Label, Row, Col, Collapse } from 'reactstrap';

import ToggleButton from '../ToggleButton';
import { SOURCES } from './sources'

const SourcesComponent = (props) => {

  const [ open, setOpen ] = useState(false)

  return (
    <Row className="sources-section">
      <ToggleButton open={open} handler={setOpen} className="sources-toggle" />
      <Col md={12}>
        <h3></h3>
        <Label >Sources: </Label>
        <Collapse isOpen={open}>
          {
            SOURCES.map((d, i) => {
              return(<div key={`source-${i}`} style={{marginBottom: "5px"}} >
                <p style={{marginBottom: "0px", fontSize: "10pt"}} >{d.description}</p>
                <span style={{fontSize: "10pt"}} >
                  <a target="_blank" rel="noreferrer" href={`${d.link}`}>{d.link}</a>
                </span>
              </div>);
            })
          }
        </Collapse>
      </Col>
    </Row>
  );
};

export default SourcesComponent;