import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "reactstrap";

import logo from "../public/images/cove_logo_outline-01.svg";

const Footer = (props) => {
  return (
    <div className="py-4 footer-area d-print-none">
      <div className="container mx-auto" style={{ width: "520px" }}>
        <p className="text-left">
          <a
            href="https://oaa.on.ca/Terms-of-use"
            target="_blank"
            rel="noreferrer"
            style={{ color: "black", textDecoration: "underline" }}
          >
            Terms
          </a>
        </p>
        <p className="text-left">
          The OAA welcomes any questions, comments, and feedback. Please direct inquiries or
          accommodation requests to <a href="mailto:oaamail@oaa.on.ca">oaamail@oaa.on.ca</a>.
        </p>
        <p className="text-left">
          Copyright © {new Date().getFullYear()} Ontario Association of Architects. All Rights
          Reserved.
        </p>
        <div className="footer-logo text-center">
          Powered by cove.tool{" "}
          <a href="https://www.cove.tools/" target="_blank" rel="noreferrer">
            <img src={logo} alt="cove" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
