import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";

const ToggleButton = (props) => {
  const { open, handler, className } = props;

  return (
    <div
      className={`primary-form-toggle-button ${
        open ? "toggle-open" : ""
      } ${className}`}
      onClick={() => handler(!open)}
    >
      <span aria-hidden="true">
        <FontAwesomeIcon icon={faCaretRight} />
      </span>
    </div>
  );
};

ToggleButton.propTypes = {
  open: PropTypes.bool,
  handler: PropTypes.func,
  className: PropTypes.string,
};

export default ToggleButton;
