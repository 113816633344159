import * as ActionTypes from "./ActionTypes";
const api_url = process.env.REACT_APP_API_URL;

export const requestLogin = (creds) => {
  return {
    type: ActionTypes.LOGIN_REQUEST,
    creds,
  };
};

export const receiveLogin = (response) => {
  return {
    type: ActionTypes.LOGIN_SUCCESS,
    response: response,
  };
};

export const failedLogin = (response) => {
  return {
    type: ActionTypes.LOGIN_FAILURE,
    response: response,
  };
};

export const loginUser = (creds) => (dispatch) => {
  const user = { username: creds.username };
  dispatch(requestLogin(user));

  return fetch("" + api_url + "users/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(creds),
  })
    .then((res) => {
      if (res.ok) {
        return res;
      } else {
        return {
          success: false,
          status: "error",
          message: `Error: ${res.status}: ${res.statusText}`,
        };
      }
    })
    .then((res) => {
      try {
        return res.json();
      } catch {
        return res;
      }
    })
    .then((res) => {
      if (res.success) {
        localStorage.setItem("token", res.token);
        localStorage.setItem("creds", JSON.stringify(res.user));
        console.log(res.user, "USER!!");
        dispatch(receiveLogin(res));
      } else {
        dispatch(failedLogin(res));
      }
    })
    .catch((err) => console.log(err));
};

export const requestLogout = () => (dispatch) => {
  return {
    type: ActionTypes.LOGOUT_REQUEST,
  };
};

export const receiveLogout = () => {
  return {
    type: ActionTypes.LOGOUT_SUCCESS,
  };
};

export const postEstimate = (values, auth) => (dispatch) => {
  console.log(values, "values");
  return fetch(api_url + "estimates", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "bearer " + auth.token,
      userkey: auth.user.UserId,
    },
    body: JSON.stringify(values),
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        return {
          success: false,
          status: "error",
          message: `Error: ${res.status}: ${res.statusText}`,
        };
      }
    })
    .then((res) => {
      if (res.success) {
        dispatch(addEstimate(res.estimate));
      } else {
        console.log(res, "addEstimate : 91");
      }
    })
    .catch((err) => console.log(err, "addEstimate : 93"));
};

export const addEstimate = (estimates) => {
  return {
    type: ActionTypes.ADD_ESTIMATE,
    payload: estimates,
  };
};

// Logs the user out
export const logoutUser = () => (dispatch) => {
  dispatch(requestLogout());
  localStorage.removeItem("token");
  localStorage.removeItem("creds");
  dispatch(receiveLogout());
};
