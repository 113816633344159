export const converter = (val, constant, multiply = true, rounded = true) => {
  val = Number(val);
  if (rounded) {
    return multiply ? rounder(val * constant) : rounder(val / constant);
  } else {
    return multiply ? val * constant : val / constant;
  }
};

export const rounder = (num) => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

export const normalizeValue = (value, base) => {
  if (!isNaN(value) && !isNaN(base)) {
    let number = (Number(value) / Number(base)) * 100;
    return number > 0 && number < 1 ? 1 : number;
  } else {
    return 0;
  }
};

export const dateParser = (date = null) => {
  const dateObject = date ? new Date(date) : new Date();
  return dateObject.toLocaleDateString("en-gb", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

export const carbonFootprintValue = (model) => {
  let renewables = Number(model["onSiteContributions"]) +
    Number(model["usingElectricity"]) +
    Number(model["usingNaturalGas"]);
  let area = Number(model["conditionedArea"]),
    electricUse = (Number(model["monthlyElectricUse"]) - renewables) * 0.000031,
    oil = converter(Number(model["heatingOil"]), 10.36, false, false) * 0.00273,
    gas =
      converter(Number(model["naturalGasUse"]), 10.2764, false, false) *
      0.00263,
    propane =
      (converter(Number(model["propaneUse"]), 6.3267, false, false) * 1.38167) /
      area;

  if (area == 0) {
    return 0;
  }
  let kg_val = ((electricUse + oil + gas) / area) * 1000 + propane;
  kg_val = isNaN(kg_val) ? 0 : kg_val;
  let mt_val = (kg_val / 1000) * area;
  mt_val = mt_val.toFixed(1);
  kg_val = kg_val.toFixed(1);

  return {
    kg_val: kg_val,
    mt_val: mt_val,
  };
};

//basic divisor
export const donutValueDivisor = (fields, model) => {
  let area = Number(model["conditionedArea"]);
  if (area == 0) {
    return 0;
  }
  let number =
    fields.reduce((acc, field) => acc + Number(model[field]), 0) / area;
  (number = number > 0 && number.toFixed(0) == 0 ? number : number.toFixed(0)),
    "donut";
  return number;
};

export const dataForDonutChart = (model) => {
  return [
    { name: "Oil", value: donutValueDivisor(["heatingOil"], model) || 0 },
    {
      name: "Nat. Gas",
      value: donutValueDivisor(["naturalGasUse"], model) || 0,
    },
    { name: "Propane", value: donutValueDivisor(["propaneUse"], model) || 0 },
    {
      name: "Electricity",
      value: donutValueDivisor(["monthlyElectricUse"], model) || 0,
    },
    { name: "BioFuel", value: donutValueDivisor(["biofuelUse"], model) || 0 },
    {
      name: "Renewables",
      value:
        donutValueDivisor(
          ["onSiteContributions", "usingElectricity", "usingNaturalGas"],
          model
        ) || 0,
    },
  ];
};

//Basic aggregator for model numbers
export const basicMetricComputation = (m, fields) => {
  let aggregate = fields.reduce((acc, field) => Number(m[field]) + acc, 0);
  let area = Number(m["conditionedArea"]);
  if (area == 0) {
    return 0;
  }
  let renewables =
    Number(m["onSiteContributions"]) +
    Number(m["usingElectricity"]) +
    Number(m["usingNaturalGas"]);
  let val = ((aggregate - renewables) / area).toFixed(0);
  return isNaN(val) ? 0 : Number(val);
};

//Validatiion helpers
export const required = (val) => val && val.length;
export const maxLength = (len) => (val) => !val || val.length <= len;
export const minLength = (len) => (val) => val && val.length >= len;
export const isNumber = (val) => !isNaN(Number(val));
export const validEmail = (val) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val);
