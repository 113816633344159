import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import * as d3 from "d3";

const createLineIndicator = (el) => {
  var container = d3.select("#" + el);
  container.selectAll("#" + el + " > *").remove();
  var svg = container
    .append("svg")
    .attr("width", "30px")
    .attr("height", "175px");

  var g = svg.append("g").attr("transform", "translate(10, 0)");

  let rect = g
    .append("rect")
    .attr("width", "4")
    .attr("height", "175")
    .style("fill", "grey");
};

const createBandedBar = (el) => {
  var container = d3.select("#" + el);
  container.selectAll("#" + el + " > *").remove();
  var svg = container
    .append("svg")
    .attr("width", "100%")
    .attr("height", "35px");
  var g = svg.append("g");

  //linear gradients to get the band to hold the correct colors.
  var defs = svg.append("defs");
  var linearGradient = defs
    .append("linearGradient")
    .attr("id", "linear-gradient");

  linearGradient
    .attr("x1", "0%")
    .attr("y1", "0%")
    .attr("x2", "100%")
    .attr("y2", "0%");

  //green
  linearGradient
    .append("stop")
    .attr("offset", "0%")
    .attr("stop-color", "#00ff00");

  //yellow
  linearGradient
    .append("stop")
    .attr("offset", "50%")
    .attr("stop-color", "#ffff00");

  //red
  linearGradient
    .append("stop")
    .attr("offset", "100%")
    .attr("stop-color", "#ff0000");

  let rect = g
    .append("rect")
    .attr("width", svg.attr("width"))
    .attr("height", "40")
    .style("fill", "url(#linear-gradient)");
};

const BarBand = (props) => {
  const { metric, element, isPrinting } = props;

  const scaleMetric = (metric) => {
    return metric > 99 ? 99 : metric <= 0 ? 0 : metric;
  };

  const line = "line-indicator" + element;
  const bar = "barband-container" + element;

  useEffect(() => {
    //reateLineIndicator(line);
    createBandedBar(bar);
  }, []);

  return (
    <div style={{ maxWidth: "100%" }}>
      <div
        id={"estimate-arrow-container" + element}
        style={{
          paddingLeft: `calc( ${scaleMetric(metric)}% - ( (100% - 300px) ) )`,
          marginLeft: "-13px",
        }}
      >
        <FontAwesomeIcon icon={faCaretDown} className="indicator-caret" />
      </div>
      <div
        id={bar}
        title="Color spectrum from green (good) on the left to red (bad) on the right"
      ></div>
      <div className="chart-text">
        <p>
          One kilowatt hour/m{"\u00B2"}= A toaster running for 1 hour a year for
          every square metre of your building.
        </p>
        {!isPrinting ? (
          <>
            <br></br>

            <p id="help-text">
              If you need help, you can learn more about this tool and see a
              video tutorial on how to use it by clicking{" "}
              <a
                href="https://oaa.on.ca/knowledge-and-resources/climate-stability/teui"
                target="_blank"
                rel="noreferrer noopener"
              >
                here.
              </a>
            </p>
          </>
        ) : null}
      </div>
    </div>
  );
};

BarBand.propTypes = {
  metric: PropTypes.number,
  element: PropTypes.string,
  isPrinting: PropTypes.bool,
};

export default BarBand;
