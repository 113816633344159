import React, { useState, useEffect } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { Button, Label, Row, Col, Input } from 'reactstrap';
import { Control, Form, Errors, actions} from 'react-redux-form';
import PropTypes from "prop-types";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import BasicInputField from '../../components/reduxformcomponents/BasicInputField';
import ConditionedInput from '../../components/reduxformcomponents/ConditionedInput';
import CollapsibleInput from '../../components/reduxformcomponents/CollapsibleInput';
import SourcesComponent from '../../components/reduxformcomponents/SourcesComponent';
import InfoCon from '../../components/InfoCon';
import CustomModal from '../../components/CustomModal';

import {
  required,
  maxLength,
  minLength,
  isNumber,
  validEmail
} from '../../utilities/conversionMethods';

/**
 * Note: Weird quirk with persisting date string with the below widget,
 * requires mapProp for initial value. - CRDenaux
 */
import DatePicker from 'react-date-picker';

import { fieldValues } from './fieldValues'

const PrimaryForm = (props) => {

  //grab items from store for more complex form element customization
  const { model, dispatch, auth } = props;

  return (
    <div className="primary-form d-print-none mt-2">
      <h2 style={{fontSize: "20pt", marginBottom: "0px", fontWeight: "bold", display: "inline-block"}}>TEUI CALCULATOR</h2>
      <p className="subhead-description mt-2">
        <strong>
          {fieldValues.subhead.description}
          <CustomModal
            body={fieldValues.subhead.modalText}
          />
        </strong>
      </p>
      <Form model='model' onSubmit={(e) => handleSubmit(e)}>
        <h6 className="pt-2 form-sub-head">Enter details to get an EUI estimate:</h6>
        <ConditionedInput
	      	model={model}
	      	text={fieldValues.conditionedArea.label}
          toolText={fieldValues.conditionedArea.toolText}
	      	field="conditionedArea"
	      	validators={{
	               isNumber
	        }}
	        dispatch={dispatch}
          conversionConstant={10.764}
	      />
        <CollapsibleInput
          model={model}
          text={fieldValues.monthlyElectricUse.label}
          toolText={fieldValues.monthlyElectricUse.toolText}
          field="monthlyElectricUse"
          validators={{
            isNumber
          }}
          dispatch={dispatch}
          fieldValues={fieldValues.monthlyElectricUse}
         />
        <CollapsibleInput
          model={model}
          text={fieldValues.naturalGasUse.label}
          toolText={fieldValues.naturalGasUse.toolText}
          field="naturalGasUse"
          validators={{
            isNumber
          }}
          dispatch={dispatch}
          fieldValues={fieldValues.naturalGasUse}
         />
        <CollapsibleInput
          model={model}
          text={fieldValues.propaneUse.label}
          toolText={fieldValues.propaneUse.toolText}
          field="propaneUse"
          validators={{
            isNumber
          }}
          dispatch={dispatch}
          fieldValues={fieldValues.propaneUse}
         />
        <CollapsibleInput
          model={model}
          text={fieldValues.heatingOil.label}
          toolText={fieldValues.heatingOil.toolText}
          field="heatingOil"
          validators={{
            isNumber
          }}
          dispatch={dispatch}
          fieldValues={fieldValues.heatingOil}
         />
        <CollapsibleInput
          model={model}
          text={fieldValues.biofuelUse.label}
          toolText={fieldValues.biofuelUse.toolText}
          field="biofuelUse"
          validators={{
            isNumber
          }}
          dispatch={dispatch}
          fieldValues={fieldValues.biofuelUse}
         />
        <CollapsibleInput
          model={model}
          text={fieldValues.onSiteContributions.label}
          toolText={fieldValues.onSiteContributions.toolText}
          field="onSiteContributions"
          validators={{
            isNumber
          }}
          dispatch={dispatch}
          fieldValues={fieldValues.onSiteContributions}
         />
        <Row>
          <Col md={12}>
            <Label>8. Enter Evaluation Period: </Label>
          </Col>
        </Row>
        <Row className="pb-1">
          <Col md={6} xs={6}>
            <Row>
              <Col>
                <Label>From: </Label>
              </Col>
            </Row>
            <Row>
              <Col>
                <Control
                  model={`model.beginDate`}
                  component={DatePicker}
                  mapProps={{
                    value: (value) => model.beginDate !== "" ? new Date(model.beginDate) : new Date()
                  }}
                  changeAction={ (model, val) => {
                    dispatch(actions.change( model, val ? val : new Date() ))
                  }}
                />
              </Col>
            </Row>
          </Col>

          <Col md={6} xs={6}>
            <Row>
              <Col>
                <Label>To: </Label>
              </Col>
            </Row>
            <Row>
              <Col>
                <Control
                  model={`model.endDate`}
                  component={DatePicker}
                  mapProps={{
                    value: (value) => model.endDate !== "" ? new Date(model.endDate) : new Date()
                  }}
                  changeAction={ (model, val) => {
                    dispatch(actions.change( model, val ? val : new Date() ))
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Label>
              {fieldValues.bullfrog.label}
              {"\u0009"}<InfoCon id={`Tooltip-bullfrog`} text={fieldValues.bullfrog.toolText} />
            </Label>

          </Col>
        </Row>
        <Row className="form-group input-group-sm">
          <Col md={6} xs={6}>
            <BasicInputField
              text={"Renewable Electricity: "}
              field="usingElectricity"
              placeholder={0}
              units="kWh/yr"
          />
          </Col>
          <Col md={6} xs={6}>
            <BasicInputField
              text={"Green Natural Gas: "}
              field="usingNaturalGas"
              placeholder={0}
              units="kWh/yr"
          />
          </Col>
        </Row>
        <h6 className="mt-4 form-sub-head">Enter project details to generate a client report (OAA Members Only):</h6>
        <Row className="form-group input-group-sm">
          <Col md={6} xs={6}>
            <BasicInputField
              text={fieldValues.email.label}
              toolText={auth.isAuthenticated ? fieldValues.email.toolText : fieldValues.nonAuthTooltipText}
              field="email"
              placeholder={fieldValues.email.placeholder}
              validators={{ required, validEmail }}
              validatorMessages={{
                  required: 'Required. ',
                  validEmail: 'Invalid Email Address.'
              }}
              readOnly={auth.isAuthenticated ? false: true}
              onBlur={() => dispatch(actions.validate('model.email', validEmail))}
          />
          </Col>
          <Col md={6} xs={6}>
            <BasicInputField
              text={fieldValues.userInputId.label}
              toolText={auth.isAuthenticated ? fieldValues.userInputId.toolText : fieldValues.nonAuthTooltipText}
              field="userInputId"
              placeholder={fieldValues.userInputId.placeholder}
              validators={{ required }}
              validatorMessages={{
                  required: 'Required. '
              }}
              readOnly={auth.isAuthenticated ? false: true}
          />
          </Col>
        </Row>
        <Row>
          <Col md={6} xs={6}>
            <BasicInputField
              text={fieldValues.projectNumber.label}
              toolText={auth.isAuthenticated ? fieldValues.projectNumber.toolText : fieldValues.nonAuthTooltipText}
              field="projectNumber"
              placeholder={fieldValues.projectNumber.placeholder}
              validators={{
                    required
                }}
              validatorMessages={{
                  required: 'Required. ',
              }}
              readOnly={auth.isAuthenticated ? false: true}
              onBlur={() => dispatch(actions.validate('model.projectNumber', required))}
          />
          </Col>
          <Col md={6} xs={6}>
            <BasicInputField
              text={fieldValues.companyName.label}
              toolText={auth.isAuthenticated ? fieldValues.companyName.toolText : fieldValues.nonAuthTooltipText}
              field="companyName"
              placeholder={fieldValues.companyName.placeholder}
              validators={{ required }}
              validatorMessages={{
                  required: 'Required. '
              }}
              readOnly={auth.isAuthenticated ? false: true}
          />
          </Col>
        </Row>
        <SourcesComponent />
      </Form>
    </div>
  );

}

PrimaryForm.propTypes = {
  model: PropTypes.object,
  auth: PropTypes.object,
  dispatch: PropTypes.func
};

const mapStateToProps = state => {
  return {
    model: state.model,
    auth: state.auth
  }
}

export default withRouter(connect(mapStateToProps)(PrimaryForm));

