import React, { useEffect } from 'react';
import PropTypes from "prop-types";
import { Label, InputGroup, InputGroupText, InputGroupAddon } from 'reactstrap';
import { Control, Errors } from 'react-redux-form';
import InfoCon from '../InfoCon';

const InputField = (props) => {

  const { placeholder, field, text, validators, validatorMessages, toolText, readOnly, units } = props;

  useEffect(()=>{}, [readOnly])
   
  return (
    <React.Fragment>
      <Label htmlFor={`model.${field}`}>{text}
        {
          toolText && <InfoCon id={`Tooltip-${field}`} text={toolText} />
        }
      </Label>
      <InputGroup>
        <Control.text 
          model={`model.${field}`} 
          id={`model.${field}`} 
          className="form-control small-input"
          size="sm"
          placeholder={placeholder}
          validators={validators}
          readOnly={readOnly}
        />
        { units && units != "" && 
        <InputGroupAddon addonType="append">
          <InputGroupText>{units}</InputGroupText>
        </InputGroupAddon> 
        }
      </InputGroup>
      <Errors
        className="text-danger"
        model={`.${field}`}
        show={{touched: true, focus: false}}
        messages={validatorMessages}
      /> 
    </React.Fragment>
  );
}

InputField.propTypes = {
  field: PropTypes.string,
  text: PropTypes.string,
  placeholder: PropTypes.any,
  toolText: PropTypes.string,
  validators: PropTypes.object,
  validatorMessages: PropTypes.object,
  readOnly: PropTypes.bool,
  units: PropTypes.string
};

export default InputField;
