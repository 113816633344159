import React from "react";
import PropTypes from "prop-types";

const CustomContainer = (props) => {
  return (
    <div className={`container custom-body-container ${props.classes}`}>
      {props.children}
    </div>
  );
};

CustomContainer.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.string,
};

export default CustomContainer;
