import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { Col, Row } from "reactstrap";
import House from "../../components/chartcomponents/House";
import BarBand from "../../components/chartcomponents/BarBand";
import DonutChart from "../../components/chartcomponents/DonutChart";
import ButtonContainer from "../../components/ButtonContainer";

import OAALogo from "../../public/images/oaa.svg";

import {
  normalizeValue,
  dateParser,
  carbonFootprintValue,
  donutValueDivisor,
  dataForDonutChart,
  basicMetricComputation,
} from "../../utilities/conversionMethods";

import { postEstimate } from "../../redux/ActionCreators";

const Estimate = (props) => {
  const { model, auth, postEstimate, forms, print } = props;

  const [toggled, setToggled] = useState(false);

  const fields = [
    "biofuelUse",
    "monthlyElectricUse",
    "heatingOil",
    "propaneUse",
    "naturalGasUse",
  ];

  const value = basicMetricComputation(model, fields);

  const carbonFootprint = carbonFootprintValue(model);

  return (
    <React.Fragment>
      <Row
        id={`estimate-container${print ? "-print" : ""}`}
        className={`justify-content-center flex-lg-column flex-xl-row mr-0 ${
          auth.isAuthenticated ? "" : "non-auth-printing"
        }`}
      >
        <Col lg={6} className={"mx-lg-auto px-lg-auto "}>
          <div
            style={{ maxWidth: "300px", minWidth: "300px" }}
            className={"mx-auto"}
          >
            <House metric={value} />
            <BarBand
              isPrinting={print}
              metric={normalizeValue(value, 500)}
              element={print ? "print" : ""}
            />
          </div>
          <div className="d-none d-sm-none d-lg-none d-xl-block d-xl-block">
            {auth.isAuthenticated && !print && (
              <ButtonContainer
                title={`${model.companyName}-${model.projectNumber}.pdf`}
                postEstimate={postEstimate}
                model={model}
                auth={auth}
                setToggled={setToggled}
                disabled={!forms.$form.valid}
              />
            )}
          </div>
        </Col>
        <Col
          lg={6}
          className={
            "ml-md-auto px-sm-4 my-sm-2 mx-sm-auto mx-xs-4 px-xs-4 third-column"
          }
        >
          <div style={{ maxWidth: "320px", margin: "0 auto" }}>
            <h2
              style={{
                fontSize: "20pt",
                marginBottom: "0px",
                fontWeight: "bold",
                display: "inline-block",
              }}
            >
              TEUI RATING
            </h2>
            <DonutChart
              data={dataForDonutChart(model)}
              element={print ? "print" : ""}
            />
          </div>
          <Row className={"text-container px-sm-2 flex-nowrap"}>
            <Col md={2} className={"co2-number pl-xl-4 pl-md-0"}>
              <h2 style={{ marginBottom: "0rem", color: "black" }}>
                {carbonFootprint.kg_val}
              </h2>
              <span
                style={{
                  fontSize: "12px",
                  color: "black",
                  fontWeight: "700",
                  marginBottom: "1rem",
                }}
              >
                kgCO{"\u2082"}e/m{"\u00B2"}
              </span>
              <h3>GHGI</h3>
            </Col>
            <Col md={8} className={"pr-lg-2 pr-md-0 pr-sm-2"}>
              <Row className="grid-list">
                <Col md={7} sm={6}>
                  <span>This Building</span>
                </Col>
                <Col md={5} sm={6} className={"pr-lg-2 pr-md-0 pr-sm-2"}>
                  <span>
                    {carbonFootprint.mt_val}MT CO{"\u2082"}e
                  </span>
                </Col>
              </Row>
              <Row className="grid-list">
                <Col md={7} sm={6}>
                  <span>Canadian Average</span>
                </Col>
                <Col md={5} sm={6} className={"pr-lg-2 pr-md-0 pr-sm-2"}>
                  <span>6.35MT CO{"\u2082"}e</span>
                </Col>
              </Row>
              <Row className="grid-list">
                <Col md={7} sm={6}>
                  <span style={{ color: "#444" }}>2030 Target</span>
                </Col>
                <Col md={5} sm={6} className={"pr-lg-2 pr-md-0 pr-sm-2"}>
                  <span style={{ color: "#444" }}>1.00 MT CO{"\u2082"}e</span>
                </Col>
              </Row>
              <Row className="grid-list">
                <Col md={7} sm={6}>
                  <span style={{ color: "#777" }}>2050 Target</span>
                </Col>
                <Col md={5} sm={6} className={"pr-lg-2 pr-md-0 pr-sm-2"}>
                  <span style={{ color: "#777" }}>0.00 MT CO{"\u2082"}e</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <div
            className={auth.isAuthenticated ? "printer" : ""}
            style={{ display: print ? "block" : "none" }}
          >
            <Row className="px-2">
              <Col className=" pt-4 pb-1 estimate-basic-info">
                <p>
                  Area & Utility Data collected:{" "}
                  <strong>February 21, 2019</strong>
                </p>
                <p>
                  Date Printed: <strong>{dateParser()}</strong>
                </p>
                <p>
                  Evaluation Period:{" "}
                  <strong>{`${dateParser(model.beginDate)} - ${dateParser(
                    model.endDate
                  )}`}</strong>
                </p>
                <p>
                  Project No. <strong>{model.projectNumber}</strong> Gross Area:{" "}
                  <strong>
                    {model.conditionedArea}
                    {" m\u00B2"}
                  </strong>
                </p>
                <p>
                  Evaluated by: <strong>{model.companyName}</strong>
                </p>
              </Col>
            </Row>
            <Row className="px-2 mt-2">
              <Col className="p-2 text-center">
                <img
                  src={OAALogo}
                  alt="Ontario Association of Architects "
                  id="oaa-logo"
                />
              </Col>
            </Row>
            <Row className="px-4 mt-2">
              <Col>
                <p style={{ fontSize: "12px" }} className="text-center">
                  Information and calculations provided in this report are for
                  informational purposes only and subject to the terms and
                  conditions provided at
                  <a href="https://oaa.on.ca/Terms-of-use">
                    {" "}
                    https://oaa.on.ca/Terms-of-use
                  </a>
                </p>
              </Col>
            </Row>
          </div>
          <div
            className="d-lg-block d-xl-none mx-auto"
            style={{ maxWidth: "300px" }}
          >
            {auth.isAuthenticated && !print && (
              <ButtonContainer
                title={`${model.companyName}-${model.projectNumber}.pdf`}
                postEstimate={postEstimate}
                model={model}
                auth={auth}
                setToggled={setToggled}
                disabled={!forms.$form.valid}
              />
            )}
          </div>
        </Col>
      </Row>
      <Row
        id="estimate-container-non-auth"
        className={`${
          !print && auth.isAuthenticated
            ? "empty-print"
            : "non-auth-printing-hidden"
        }`}
      >
        <Col>
          <h1>Printing only available for authorized OAA members</h1>
        </Col>
      </Row>
    </React.Fragment>
  );
};

Estimate.propTypes = {
  model: PropTypes.object,
  auth: PropTypes.object,
  postEstimate: PropTypes.func,
  forms: PropTypes.object,
  print: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  postEstimate: (values, auth) => dispatch(postEstimate(values, auth)),
});

const mapStateToProps = (state) => {
  return {
    model: state.model,
    auth: state.auth,
    forms: state.forms,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Estimate)
);
