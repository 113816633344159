import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";

const SideDrawer = (props) => {
  //const { sideOpen, setSideOpen } = props;
  return <div>{props.children}</div>;
};

SideDrawer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SideDrawer;
