export const SOURCES = [{
		description: "Conversion of Natural Gas to Gigajoules (m3 to GJ) multiply m3 by 0.0373",
		link: "https://www.nrcan.gc.ca/energy/natural-gas/5641#conversion"
	},
	{
		description: "Conversion of GigaJoules to kWhr (GJ>kWh) multiply GJ by 277 ",
		link: "https://www.nrcan.gc.ca/energy/natural-gas/5641#conversion"
	},
	{
		description: "Conversion of GigaJoules to kWhr (GJ>kWh) multiply GJ by 277 ",
		link: "https://www.nrcan.gc.ca/energy/natural-gas/5641#conversion"
	},
	{
		description: "Conversion of Square Feet to Square Metres: Multiply sf by 10.7639",
		link: ""
	},
	{
		description: "Conversion Factor for MTCO2 from m3 of natural Gas (Direct & Inderect) Multiply m3 by 0.00263",
		link: "Environment Canada, Emission Factors from Canada’s GHG Inventory. Available at: http://www.ec.gc.ca/ges-ghg/"
	},
	{
		description: "31g CO2eq/kWh provided courtesy of The Atmospheric Fund",
		link: "https://live.gridwatch.ca/home-page.html"
	},
	{
		description: "Wood heat conversion factor: 1,000kWh/m3 ",
		link: "http://seacourse.dk/wiki/tiki-index.php?page=Calculating+in+KWh"
	},
	{
		description: "Convert GtCarbon to CO2ppm Atmospheric: 1ppm = 7.81GtCO2 and 1ppmCO2a = 2.13Gt unburned carbon.",
		link: "https://www.skepticalscience.com/print.php?r=45 "
	},
	{
		description: `2795Gt of Carbon then equates to 1312.2ppmCO2a (‘business as usual’ case scenario per IPCC RCP8.5, 2018)
			*2795Gt is the worst case TOTAL scenario for 5ºC of warming (per ICPP RCP8.5), and 15% of 2795Gt is 419.25Gt CO2 (the percentage attributable globally
			to buildings). Canadian Residential Sector Energy Use:`,
		link: "http://oee.nrcan.gc.ca/corporate/statistics/neud/dpa/showTable.cfm?type=CP&sector=res&juris=ca&rn=1&page=0"
	}
]

