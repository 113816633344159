import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Button, Row, Col } from 'reactstrap';
import PrimaryForm from '../PrimaryForm'
import Estimate from '../Estimate'
import CustomContainer from "../../components/CustomContainer";
import SideDrawer from "../../components/SideDrawer";

const Landing = (props) => {
	return (
  <React.Fragment>
    <Row className="page-container" >
      <Col md={6} lg={4} className={`mt-4 ml-2 d-none d-md-block d-print-none`}>
        <SideDrawer className="hidden-xs" >
          <PrimaryForm />
        </SideDrawer>
      </Col>
      <Col md={{size: 6}} lg={8}  className={`mt-4 pr-1 print-show-all`} >
        <Estimate />
        <CustomContainer classes="d-md-none ">
          <PrimaryForm />
        </CustomContainer>
      </Col>
      
    </Row>
    <Estimate print={true} />
  </React.Fragment>
	)
}

export default Landing;