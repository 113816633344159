import { createStore, applyMiddleware, combineReducers } from "redux";
import { createForms, formReducer } from "react-redux-form";
import { initialState } from "./reducer";
import { persistStore, persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";

import { UserAuth } from "./userAuth";
import { Estimates } from "./estimates";

import thunk from "redux-thunk";
import logger from "redux-logger";

console.log(UserAuth, "userAuth!!!");

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["model"],
  stateReconciler: autoMergeLevel2,
};

const rootReducer = combineReducers({
  auth: UserAuth,
  estimate: Estimates,
  ...createForms({
    model: initialState,
  }),
});

//const modelFormReducer = combineForms({ model: initialState });

const persistedReducer = persistReducer(persistConfig, rootReducer);

// If you want your entire store to have the form state...
export const configureStore = () => {
  let store = createStore(persistedReducer, applyMiddleware(thunk, logger));
  let persistor = persistStore(store);

  return { store, persistor };
};
